import axios, { AxiosInstance, AxiosRequestConfig, Method } from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { getCookiesString, parseAxiosSetCookieHeaderToCookies } from './cookie'
import { createMocks } from '../mocks'
import getNextConfig from 'next/config'
import { Alert } from 'rsuite'

const { publicRuntimeConfig } = getNextConfig()
const { DOMAIN, API_URL, WITH_MOCKS } = publicRuntimeConfig

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.browser ? API_URL : DOMAIN + API_URL,
})
export { axiosInstance }

if (WITH_MOCKS === 'true') {
  console.log('running with mocks')
  createMocks(new MockAdapter(axiosInstance, { delayResponse: 100 }))
}

export type mockResponseStatus = 'success' | 'error' | 'warning'
export type ApiResponse<T = unknown> = {
  status: mockResponseStatus
  data?: T
  reason?: string
}

export const requestApi = async <T = ApiResponse, D = Record<string, unknown>>(
  method: Method,
  url: string,
  data?: D,
  dataInParams?: boolean,
  customConfig?: D,
): Promise<T> => {
  const config: AxiosRequestConfig = {
    method: method,
    url: url,
    params: {},
    ...customConfig,
  }

  if (data) {
    switch (method) {
      case 'post':
      case 'put':
      case 'patch':
        config.data = data
        break
      default:
        config.params = { ...config.params, ...data }
        break
    }
    if (dataInParams) {
      config.params = { ...config.params, ...data }
    }
  }

  if (process.browser && data instanceof FormData) {
    config.headers = {
      'Content-Type': undefined,
      enctype: 'multipart/form-data',
    }
  }

  //Проксируем куки
  if (!process.browser) {
    const cookiesString = getCookiesString()
    if (cookiesString.length) {
      config.headers = {
        Cookie: getCookiesString(),
      }
    }
  }

  try {
    const response = await axiosInstance.request(config)
    if (
      !process.browser &&
      response.headers &&
      response.headers['set-cookie']
    ) {
      parseAxiosSetCookieHeaderToCookies(response.headers['set-cookie'])
    }
    return response.data
  } catch (error) {
    if (process.browser) {
      switch (error.response.status) {
        case 403:
          Alert.error('Доступ запрещен. Ошибка 403.')
          break
        case 409:
          throw error
        case 401:
          throw error
        case 400:
          throw error
        case 302:
          throw error
        default:
          Alert.error('Произошла ошибка: ' + error.response.status)
          break
      }
    } else {
      throw error
    }
  }
}
