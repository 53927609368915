import MockAdapter from 'axios-mock-adapter'

const mockRegionsList = [
  {
    code: 'RU_MOW',
    name: 'Москва',
  },
  {
    code: 'RU_MOS',
    name: 'Московская область',
  },
]

export const createRegionsMocks = (mock: MockAdapter): void => {
  mock.onGet('/dictionaries/regions').reply(() => [200, mockRegionsList])
}
