import Cookies, { CookieSetOptions, Cookie } from 'universal-cookie'
import requestContext from 'request-context'
const cookies: Cookies = new Cookies()

/**
 * Получить объект для работы с куками
 * @returns Cookies
 */
const getCookies = (): Cookies => {
  if (process.env.NODE_ENV !== 'test' && !process.browser) {
    return requestContext.get('request:cookies')
  }
  return cookies
}

export const getCookie = (name: string): unknown => {
  return getCookies().get(name)
}

export const setCookie = (
  name: string,
  value: Cookie,
  options: CookieSetOptions = {},
): void => {
  return getCookies().set(name, value, options)
}

export const removeCookie = (name: string, path?: string): void => {
  return getCookies().remove(name, { path: path || '/' })
}

export const getCookiesString = (): string => {
  const cookies = Object.entries(getCookies().getAll())
  if (cookies.length) {
    return cookies
      .map((cookie) => {
        return `${cookie[0]}=${cookie[1]}`
      })
      .join('; ')
  }
  return ''
}
const MAPPED_OPTIONS = {
  httponly: ['httpOnly', true],
  'max-age': ['maxAge'],
}
/**
 * Прокидываем куки полученные на сервере во все следующие запросы
 * @param setCookieHeader
 */
export const parseAxiosSetCookieHeaderToCookies = (
  setCookieHeader: [string],
): void => {
  setCookieHeader.forEach((cookieString) => {
    const cookieParts = cookieString.split(';')
    const [name, value] = cookieParts.shift().split('=')
    const options = {}
    cookieParts.forEach((cookiePart) => {
      let [optName, optValue] = cookiePart.split('=')
      const formattedOptName = optName.trim().toLowerCase()

      if (MAPPED_OPTIONS[formattedOptName]) {
        optName = MAPPED_OPTIONS[formattedOptName][0]
        optValue = optValue || MAPPED_OPTIONS[formattedOptName][1] || null
        if (optValue) {
          options[optName] = optValue
        }
      } else {
        if (!optValue) {
          return
        }
        options[formattedOptName] =
          formattedOptName === 'expires'
            ? new Date(optValue.trim())
            : optValue.trim()
      }
    })
    setCookie(name, value, options)
  })
}
