import MockAdapter from 'axios-mock-adapter'

export const createDashboardMocks = (mock: MockAdapter): void => {
  mock.onGet('/dashboard').reply(() => {
    return [
      200,
      {
        facebook: [
          {
            id: 1,
            name: 'Губерния Хабаровск',
            loyalty: 0,
            subscriberCount: 2500,
            publicGroupType: 1,
            type: 2,
          },
          {
            id: 2,
            name: 'Вести - Хабаровск',
            loyalty: 2,
            subscriberCount: 2500,
            publicGroupType: 0,
            type: 0,
          },
          {
            id: 3,
            name: 'Новости. Хабаровск - PROkhab.ru',
            loyalty: 2,
            subscriberCount: 1500,
            publicGroupType: 2,
            type: 1,
          },
        ],
        instagram: [
          {
            id: 1,
            name: 'Губерния Хабаровск',
            loyalty: 1,
            subscriberCount: 2500,
            publicGroupType: 1,
            type: 2,
          },
          {
            id: 2,
            name: 'Вести - Хабаровск',
            loyalty: 0,
            subscriberCount: 2500,
            publicGroupType: 0,
            type: 1,
          },
          {
            id: 3,
            name: 'Губерния (GuberniaTv)',
            loyalty: 3,
            subscriberCount: 1500,
            publicGroupType: 2,
            type: 1,
          },
        ],
        odnoklassniki: [
          {
            id: 1,
            name: 'Губерния Хабаровск',
            loyalty: 0,
            subscriberCount: 2500,
            publicGroupType: 1,
            type: 0,
          },
          {
            id: 2,
            name: 'Вести - Хабаровск',
            loyalty: 2,
            subscriberCount: 2500,
            publicGroupType: 0,
            type: 1,
          },
          {
            id: 3,
            name: 'Губерния (GuberniaTv)',
            loyalty: 0,
            subscriberCount: 1500,
            publicGroupType: 2,
            type: 0,
          },
        ],
        vk: [
          {
            id: 1,
            name: 'Губерния Хабаровск',
            loyalty: 0,
            subscriberCount: 2500,
            publicGroupType: 1,
            type: 2,
          },
          {
            id: 2,
            name: 'Вести - Хабаровск',
            loyalty: 2,
            subscriberCount: 2500,
            publicGroupType: 0,
            type: 1,
          },
          {
            id: 3,
            name: 'Губерния (GuberniaTv)',
            loyalty: 1,
            subscriberCount: 1500,
            publicGroupType: 2,
            type: 1,
          },
          {
            id: 4,
            name: 'Губерния (GuberniaTv)',
            loyalty: 0,
            subscriberCount: 1500,
            publicGroupType: 2,
            type: 2,
          },
        ],
        youtube: [],
        telegram: [],
        smi: [
          {
            id: 1,
            name: 'Губерния Хабаровск',
            loyalty: 2,
            subscriberCount: 2500,
            publicGroupType: 1,
            type: 2,
          },
          {
            id: 2,
            name: 'ИА Открытый город',
            loyalty: 0,
            subscriberCount: 2500,
            publicGroupType: 0,
            type: 1,
          },
          {
            id: 3,
            name: 'Губерния (GuberniaTv)',
            loyalty: 1,
            subscriberCount: 1500,
            publicGroupType: 2,
            type: 1,
          },
        ],
        influencers: [],
        leaders: {
          id: 3,
          name: 'Сергей Фургал, губернатор',
          title: 1,
          loyalty: 1,
          subscriberCount: 1500,
        },
        diagram: [],
      },
    ]
  })
}
