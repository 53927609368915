import MockAdapter from 'axios-mock-adapter'

export const createTableMocks = (mock: MockAdapter): void => {
  mock.onGet('/section').reply(() => {
    return [
      200,
      [
        {
          id: 1,
          socialNetwork: 2,
          groupName: 'yakutsk_news',
          subscribersCount: 152065,
          publicationFrequency: 51,
          er: 2003,
          averageViewsCount: 25,
          loyalty: 0,
          topics: ['Спорт', 'Конституция'],
          owner: [
            {
              name: 'Roman Abramovich',
              link: 'https://Roman-Abramovich',
            },
            {
              name: 'Alexey Dmitriev',
              link: 'https://Dmitriev',
            },
          ],
          partnership: 0,
        },
        {
          id: 2,
          socialNetwork: 2,
          groupName: 'yakutsk_news',
          subscribersCount: 152063,
          publicationFrequency: 50,
          er: 2050,
          averageViewsCount: 27,
          loyalty: 1,
          topics: ['Спорт', 'Конституция'],
          owner: [
            {
              name: 'Roman Abramovich',
              link: 'https://Roman-Abramovich',
            },
            {
              name: 'Alexey Dmitriev',
              link: 'https://Dmitriev',
            },
          ],
          partnership: 3,
        },
        {
          id: 3,
          socialNetwork: 2,
          groupName: 'News.Ykt.Ru: главные новости Якутии',
          subscribersCount: 152061,
          publicationFrequency: 78,
          er: 2103,
          averageViewsCount: 82,
          loyalty: 0,
          topics: ['Спорт', 'Конституция'],
          owner: null,
          partnership: 0,
        },
        {
          id: 4,
          socialNetwork: 2,
          groupName: 'SAKHAPRESS',
          subscribersCount: 152064,
          publicationFrequency: 512,
          er: 2012,
          averageViewsCount: 23,
          loyalty: 2,
          topics: ['Спорт', 'Конституция', 'Спорт'],
          owner: [
            {
              name: 'Alexey Dmitriev',
              link: 'https://Dmitriev',
            },
          ],
          partnership: 0,
        },
        {
          id: 5,
          socialNetwork: 2,
          groupName: 'Новости Якутии YAKUTIA.INFO',
          subscribersCount: 152064,
          publicationFrequency: 76,
          er: 2000,
          averageViewsCount: 34,
          loyalty: 0,
          topics: ['Спорт', 'Конституция'],
          owner: [
            {
              name: 'Alexey Dmitriev',
              link: 'https://Dmitriev',
            },
          ],
          partnership: 3,
        },
        {
          id: 6,
          socialNetwork: 1,
          groupName: 'yakutsk_news',
          subscribersCount: 152064,
          publicationFrequency: 50,
          er: 2000,
          averageViewsCount: 5,
          loyalty: 0,
          topics: ['Спорт', 'Конституция'],
          owner: [
            {
              name: 'Alexey Dmitriev',
              link: 'https://Roman-Abramovich',
            },
            {
              name: 'Alexey Dmitriev',
              link: 'https://Roman-Abramovich',
            },
          ],
          partnership: 2,
        },
        {
          id: 7,
          socialNetwork: 1,
          groupName: 'yakutsk_news',
          subscribersCount: 152064,
          publicationFrequency: 50,
          er: 2000,
          averageViewsCount: 765,
          loyalty: 2,
          topics: ['Спорт', 'Конституция'],
          owner: [
            {
              name: 'Alexey Dmitriev',
              link: 'https://Dmitriev',
            },
            {
              name: 'Roman Abramovich',
              link: 'https://Roman-Abramovich',
            },
          ],
          partnership: 0,
        },
        {
          id: 8,
          socialNetwork: 1,
          groupName: 'yakutsk_news',
          subscribersCount: 152064,
          publicationFrequency: 50,
          er: 2000,
          averageViewsCount: 27,
          loyalty: 0,
          topics: ['Спорт', 'Конституция'],
          owner: [
            {
              name: 'Alexey Dmitriev',
              link: 'https://Dmitriev',
            },
          ],
          partnership: 0,
        },
        {
          id: 9,
          socialNetwork: 2,
          groupName: 'yakutsk_news',
          subscribersCount: 152064,
          publicationFrequency: 50,
          er: 2000,
          averageViewsCount: 56,
          loyalty: 1,
          topics: ['Спорт', 'Конституция'],
          owner: [
            {
              name: 'Alexey Dmitriev',
              link: 'https://Dmitriev',
            },
            {
              name: 'Roman Abramovich',
              link: 'https://Roman-Abramovich',
            },
          ],
          partnership: 0,
        },
        {
          id: 10,
          socialNetwork: 1,
          groupName: 'yakutsk_news',
          subscribersCount: 152064,
          publicationFrequency: 50,
          er: 2000,
          averageViewsCount: 12,
          loyalty: 2,
          topics: ['Спорт', 'Конституция'],
          owner: [
            {
              name: 'Alexey Dmitriev',
              link: 'https://Dmitriev',
            },
            {
              name: 'Roman Abramovich',
              link: 'https://Roman-Abramovich',
            },
          ],
          partnership: 0,
        },
      ],
    ]
  })
  mock.onGet('/categories').reply(() => [
    200,
    [
      { id: 1, name: 'Топ-10' },
      { id: 2, name: 'Топ-20' },
      { id: 3, name: 'Топ-50' },
    ],
  ])
}
