import MockAdapter from 'axios-mock-adapter'
import { createTableMocks } from '../features/section/mocks'
import { createRegionsMocks } from '../store/models/dictionary/mocks'
import { createDashboardMocks } from '../features/dashboard/mocks'

export const createMocks = (mock: MockAdapter): void => {
  createTableMocks(mock)
  createRegionsMocks(mock)
  createDashboardMocks(mock)
  mock.onAny().passThrough()
}
